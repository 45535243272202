export const recommendations = [
  {
    title: "Graeme and Lauren recommend...",
    data: [
      { name: "Royal Botanic Garden", type: "Gardens" },
      { name: "The Pantry", type: "Brunch" },
      { name: "Herbie of Edinburgh", type: "Picnic Supplies" },
      { name: "Holyrood 9A", type: "Drinks and Pizza" },
      { name: "Bryant and Mac", type: "Cocktails" },
      { name: "Brew Dog", type: "Beer Pub" },
      { name: "The Hanging Bat", type: "Beer Bar" },
      { name: "La Casa Dalry", type: "Tapas" },
      { name: "Dishoom", type: "Indian" },
      { name: "Artisan Roast", type: "Coffee" },
      { name: "Cairngorm Coffee", type: "Coffee" },
      { name: "Victoria Street", type: "Shopping" },
    ],
  },
  {
    title: "James and Laura recommend...",
    data: [
      { name: "Leo's Beanery", type: "Coffee and Cake" },
      { name: "Circus Lane", type: "Picturesque Street" },
      { name: "Ensign Ewart", type: "Bar / Live Music" },
      { name: "The Grain Store", type: "Sunday Roast" },
      { name: "The Lookout", type: "Fine Dining with a View" },
      { name: "Dirleton Castle", type: "Historic Ruined Castle" },
      { name: "North Berwick", type: "Seaside Town" },
      { name: "Stewarts Brewing", type: "Local Brewery" },
    ],
  },
  {
    title: "Christopher and Julie recommend...",
    data: [
      { name: "Holyrood Palace", type: "King's Residence" },
      { name: "Arthur's Seat", type: "Hike with a view" },
      { name: "Cafe Andaluz", type: "Tapas" },
      { name: "Multress Walk/New St. James", type: "Shopping" },
      { name: "Hoot the Redeemer", type: "Cocktails" },
      { name: "Nightcap", type: "Drinks/Cocktails" },
      { name: "The Old Clubhouse Gullane", type: "Lunch/Dinner" },
      { name: "Gaucho", type: "Argentinian Steak" },
      { name: "Fazenda", type: "Brazillian Steak Buffet" },
      { name: "Lane 7", type: "Bowling, Shuffleboard, Pool..." },
    ],
  },
  {
    title: "Jonathan and Sinéad recommend...",
    data: [
      { name: "Piggs Wine Bar", type: "Wine, charcuterie, tapas" },
      { name: "Raeburn Hotel", type: "Cocktails and Drinks" },
      { name: "Scran & Scallie", type: "Gastropub (steak pie)" },
      { name: "Hawksmoor", type: "Sunday lunch or steak" },
      { name: "Leith Chop House", type: "Chateaubriand" },
      { name: "Nor Loft", type: "Cocktails and a View" },
      { name: "Dusit", type: "Authentic Thai" },
      { name: "Cambridge Bar", type: "Burgers" },
      { name: "Baba", type: "Lebanese" },
      { name: "Ship on the Shore", type: "Seafood" },
      { name: "New Chapter", type: "Scottish Staples" },
    ],
  },
];
